/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import colors from '@solid-ui-theme/colors';

export default {
    heroContainer: {
        position: `relative`,
        pt: [5, 6],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            width: [`full`, `90%`],
            height: `100%`,
            top: [`-10%`, 0],
            right: `50%`,
            transform: `translate(50%, 0)`,
            zIndex: -1,
        },
        '::before': {
            borderRadius: `xl`,
            background: t => `linear-gradient(
           180deg,
           ${t.colors.alphaLighter} 0%,
           ${t.colors.alphaLight} 100%
         )`,
        },
    },
    featuresContainer: {
        position: `relative`,
        py: [5, 6],
        '::before': {
            position: `absolute`,
            content: `" "`,
            size: `full`,
            top: -3,
            right: 0,
            zIndex: -1,
            borderRadius: `xl`,
            backgroundImage: t => `linear-gradient(
              to bottom,
              ${t.colors.alphaLighter},
              ${t.colors.alpha}
            )`,
        },
    },
    tabsContainer: {
        position: `relative`,
        py: [4, 5],
        '::before': {
            position: `absolute`,
            content: `" "`,
            size: `full`,
            top: 0,
            right: 0,
            zIndex: -1,
            borderRadius: `xl`,
            background: t => `linear-gradient(
                to bottom,
                ${t.colors.background},
                ${t.colors.alphaLighter}
              )`,
        },
    },
    testimonialsContainer: {
        position: `relative`,
        pt: 6,
        pb: 5,
        '::before': {
            position: `absolute`,
            content: `" "`,
            width: `full`,
            height: `full`,
            top: 0,
            right: 0,
            zIndex: -1,
            borderRadius: `xl`,
            background: `linear-gradient(
        180deg,
        #f7f9fe 0%,
        #f4f4f8 100%
      )`,
        },
    },
    strategiesContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            width: `full`,
            height: `150%`,
            position: `absolute`,
            top: 0,
            left: 0,
            zIndex: -1,
            background: t =>
                `linear-gradient(
          180deg,
          ${t.colors.alphaLighter} 0%,
          ${t.colors.background} 100%
        )`,
            backgroundSize: `100%`,
        },
    },
};
